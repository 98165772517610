import { FC, useCallback, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Link as ChakraLink,
  LinkProps,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { IMenuItem } from "../../types/IMenuItem";
import { useCheckPermission, useTracking } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { useCustomerPortal } from "api/customerPortal";
import { CHECKOUT_URL } from "constants/pages";
import { CHECKOUT_TYPE } from "constants/Texts";
import { QueryStatus } from "react-query";
import { useUpgradeModal } from "hooks/useUpgradeModal";
import ManageSubscription from "features/manageSubscription";

const activeStyle = {
  bg: theme.palette.ui.greys.grey5,
  textColor: theme.palette.brand.primary.black,
};

const disabledStyle = {
  cursor: "not-allowed",
  opacity: 0.5,
};

const MenuItem: FC<IMenuItem & LinkProps> = ({
  title,
  icon,
  to,
  isExternal = false,
  isStripePortal = false,
  isUpgrade = false,
  onTrial = false,
  close,
  feature,
  canEdit = true,
  ...props
}) => {
  const track = useTracking();
  const [
    isManageSubscriptionOpen,
    { on: openManageSubscription, off: closeManageSubscription },
  ] = useBoolean();
  const [customerPortal, setCustomerPortal] = useState("");
  const canEditMenuItem = useCheckPermission(
    FEATURE_ACTION.EDIT,
    feature as FEATURES,
  );

  const { mutate: fireCustomerPortal, status: customerPortalStatus } =
    useCustomerPortal();
  const { openModal } = useUpgradeModal();
  const routingProps = useMemo(() => {
    if (isUpgrade || !canEdit) return { href: "#" };
    if (!isExternal) return { to, as: NavLink };
    if (isExternal && canEditMenuItem && canEdit) {
      return { href: to, isExternal };
    }
  }, [isExternal, to, canEditMenuItem]);
  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      if (isStripePortal) {
        e.preventDefault();
        try {
          const data = await fireCustomerPortal({
            data: {
              cancel_url: CHECKOUT_URL.CANCEL,
              success_url: CHECKOUT_URL.SUCCESS,
              type: CHECKOUT_TYPE,
            },
          });
          if (data?.url) {
            setCustomerPortal(data?.url);
            openManageSubscription();
          }
        } catch (error) {
          console.error("Failed to get Stripe portal URL:", error);
        }
      }

      if (isUpgrade || !canEditMenuItem || !canEdit) {
        track({ upgrade: true, customTrackingProps: { source: feature } });
        openModal();
      }
      close?.();
    },
    [isUpgrade, canEditMenuItem, feature, isStripePortal],
  );

  return (
    <>
      <ChakraLink
        display="flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        p={2}
        textColor={theme.palette.ui.greys.grey1}
        fontSize="lg"
        fontWeight={600}
        transition="all 150ms ease-in-out"
        textDecoration="none"
        _hover={activeStyle}
        _activeLink={activeStyle}
        onClick={handleClick}
        {...props}
        {...routingProps}
        style={
          customerPortalStatus === QueryStatus.Loading ? disabledStyle : {}
        }
      >
        {icon}
        <Text marginX={3}>{title}</Text>
        {onTrial && <TrialTag />}
      </ChakraLink>
      <ManageSubscription
        isOpen={isManageSubscriptionOpen}
        onClose={closeManageSubscription}
        customerPortal={customerPortal}
      />
    </>
  );
};

const TrialTag: FC = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={10}
    height={5}
    paddingX={1}
    borderRadius="base"
    backgroundColor={theme.palette.brand.primary.black}
  >
    <Text
      fontSize="2xs"
      lineHeight="taller"
      fontWeight="semibold"
      textTransform="uppercase"
      color={theme.palette.brand.primary.white}
    >
      TRIAL
    </Text>
  </Box>
);

export default MenuItem;
