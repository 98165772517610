import { useQuery, QueryConfig } from "react-query";
import axios, { AxiosError } from "axios";
import api from "api";

export interface ISubscriptionInfo {
  current_subscription: ICurrentSubscription | null;
  show_yearly_only: boolean;
  show_monthly_and_yearly: boolean;
}

interface ICurrentSubscription {
  product: {
    name: string;
    interval: "month" | "year";
  };
  seats?: {
    total: number;
    invited: number;
    available: number;
    minimum: number;
  };
  status:
    | "active"
    | "canceled"
    | "past_due"
    | "unpaid"
    | "trialing"
    | "incomplete";
  price: {
    currency: string;
    billing_scheme: string;
  };
  discount?: {
    percent_off: number;
    duration: string;
    duration_in_months: number | null;
  };
  schedule: {
    current_period?: {
      start: number;
      end: number | null;
      remaining_days: number | null;
    };
    trial?: {
      start: number;
      end: number | null;
      remaining_days: number | null;
    };
    cancellation?: {
      cancel_at_period_end: boolean;
      cancel_at: number | null;
      canceled_at: number | null;
      days_until_cancel: number | null;
    };
  };
  reduction_schedule?: {
    new_seat_count: number;
    effective_date: string;
    message: string;
  };
}
type QueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
  retry?: boolean | number;
  retryDelay?: number | ((retryAttempt: number) => number);
  onSuccess?: (data: ISubscriptionInfo) => void;
  onError?: (error: Error) => void;
};

/**
 * Hook to fetch subscription information
 */
export function useSubscriptionInfo(options: QueryOptions) {
  return useQuery<ISubscriptionInfo, Error>(
    ["subscription", "info"],
    async () => {
      try {
        const response = await api.get<ISubscriptionInfo>(
          "/payments/subscription/info",
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;

          const errorMessage =
            axiosError.message || "An unknown error occurred";

          throw new Error(`Failed to fetch subscription info: ${errorMessage}`);
        }
        throw error;
      }
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes cache
      enabled: options?.enabled,
      ...options,
    },
  );
}
