/**
 * Customer Portal
 * @returns {Object} { url: string}
 */

import { useMutation } from "react-query";
import api from "./index";

interface IPortalOptions {
  mutation?: any;
  request?: Record<string, any>;
}

interface IPortalData {
  data?: Record<string, any>;
}

interface IPortalResponse {
  data: {
    url: string;
  };
}

export const customerPortal = async (
  data?: IPortalData,
  options?: Record<string, any>,
): Promise<IPortalResponse["data"]> => {
  const response = await api.post("/payments/stripe-portal", {
    data,
    ...options,
  });
  return response.data;
};

export const useCustomerPortal = (options?: IPortalOptions) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn = (props?: IPortalData) => {
    const { data } = props || {};
    return customerPortal(data, requestOptions);
  };

  const [mutate, { status, error, isLoading }] = useMutation<
    IPortalResponse["data"],
    unknown,
    IPortalData | undefined
  >(mutationFn, mutationOptions);

  return { mutate, status, error, isLoading };
};

export const seatManagementPortal = async (
  data?: IPortalData,
  options?: Record<string, any>,
): Promise<IPortalResponse["data"]> => {
  const response = await api.post("/payments/seat-management-portal", {
    data,
    ...options,
  });
  return response.data;
};

export const useSeatManagementPortal = (options?: IPortalOptions) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn = (props?: IPortalData) => {
    const { data } = props || {};
    return seatManagementPortal(data, requestOptions);
  };

  const [mutate, { status, error, isLoading }] = useMutation<
    IPortalResponse["data"],
    unknown,
    IPortalData | undefined
  >(mutationFn, mutationOptions);

  return { mutate, status, error, isLoading };
};

export const updateSeatManagementPortal = async (
  data?: IPortalData,
  options?: Record<string, any>,
): Promise<IPortalResponse["data"]> => {
  const response = await api.put("/payments/seat-management-portal", {
    data,
    ...options,
  });
  return response.data;
};

export const useUpdateSeatManagementPortal = (options?: IPortalOptions) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn = (props?: IPortalData) => {
    const { data } = props || {};
    return updateSeatManagementPortal(data, requestOptions);
  };

  const [mutate, { status, error, isLoading }] = useMutation<
    IPortalResponse["data"],
    unknown,
    IPortalData | undefined
  >(mutationFn, mutationOptions);

  return { mutate, status, error, isLoading };
};
