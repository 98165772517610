import React from "react";

import classNames from "classnames";
import { Formik, Form } from "formik";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";

import { useUpdateTeamMutation } from "./queries";
import {
  Section,
  Button,
  Spinner,
  Switch,
} from "../../components";
import { TEXTS, VALIDATION_MESSAGES } from "../../constants";
import { setTeam, useUser } from "../../context";
import { useFeatureFlagsQuery, useTracking } from "../../hooks";
import { humanize } from "../../utils";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { UpgradeButton } from "../../components/UpgradeButton";
import useProfilePermission from "hooks/useProfilePermission";

const validationSchema = yup.object({
  company_name: yup
    .string()
    .trim()
    .max(80, VALIDATION_MESSAGES.MAX_LENGTH("Company name", 80))
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Company name")),
});

const CompanyForm = () => {
  const { addToast } = useToasts();
  const track = useTracking();
  const { canViewTeamRequireCompanyName, canEditTeamRequireCompanyName } = useProfilePermission();

  const [{ currentTeam, currentProfile }, dispatch] = useUser();
  const { data: featureFlags } = useFeatureFlagsQuery();
  const isSelfActivateEnabled = JSON.parse(
    featureFlags?.flags?.business?.isSelfActivateEnabled || "false",
  );
  const [updateTeam, { isLoading }] = useUpdateTeamMutation({
    onSuccess: (
      _data,
      { teamData: { title, allow_self_assign, non_editable_profile_fields, require_company_name } },
    ) => {
      dispatch(
        setTeam({
          ...currentTeam,
          title,
          allow_self_assign,
          non_editable_profile_fields,
          require_company_name,
        }),
      );
    },
  });

  const handleSubmit = values => {
    updateTeam(
      {
        teamID: currentTeam.id,
        teamData: {
          title: values?.company_name,
          allow_self_assign: values?.allow_self_assign,
          non_editable_profile_fields: values?.non_editable_profile_fields,
          require_company_name: values?.require_company_name,
        },
      },
      {
        onError: () => {
          addToast(
            "Failed to update team, please contact support for assistance.",
            {
              appearance: "error",
            },
          );
        },
        onSuccess: () => {
          addToast(
            "Permission updated successfully.",
            {
              appearance: "success",
            },
          );
          track({
            eventName: ANALYTICS_EVENTS.SETTINGS_SAVE_COMPANY_DETAILS_CHANGES,
            customTrackingProps: {
              values,
            },
          });
        },
      },
    );
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        company_name: currentTeam?.title,
        work_category: currentProfile?.work_category,
        allow_self_assign: currentTeam?.allow_self_assign || false,
        non_editable_profile_fields:
          currentTeam?.non_editable_profile_fields || {},
        require_company_name: currentTeam?.require_company_name || false,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form autoComplete="off">
          {isSelfActivateEnabled && (
            <Section
              label="Allow Member to self-activate card"
              subLabel="Allow employees to create their OVOU Profile with any email & activate their card themselves."
              showBorder={false}
            >
              <Switch
                id="self-assign"
                checked={values.allow_self_assign}
                onChange={checked =>
                  setFieldValue("allow_self_assign", checked)
                }
              />
            </Section>
          )}
          {!!Object.entries(values.non_editable_profile_fields).length && (
            <Section
              label="Lock Names and Job titles"
              subLabel="This allows for flexibility of locking names and job titles while giving users editing permission."
              showBorder={false}
            >
              <div
                style={{
                  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                }}
                className="grid gap-4 items-center"
              >
                {Object.entries(values.non_editable_profile_fields).map(
                  ([field, isChecked]) => (
                    <div key={`${field}-field`}>
                      <Switch
                        id={`${field}-field`}
                        label={humanize(field)}
                        checked={isChecked}
                        onChange={checked =>
                          setFieldValue(
                            `non_editable_profile_fields.${field}`,
                            checked,
                          )
                        }
                      />
                    </div>
                  ),
                )}
              </div>
            </Section>
          )}

          {canViewTeamRequireCompanyName && (
            <Section
              label={TEXTS.companyForm.requireCompanyName.label}
              subLabel={TEXTS.companyForm.requireCompanyName.subLabel}
              showBorder={false}
            >
              {
                canEditTeamRequireCompanyName ? (
                  <Switch
                    id="require_company_name"
                    label="On"
                    checked={values.require_company_name}
                    onChange={checked =>
                      setFieldValue(
                        `require_company_name`,
                        checked,
                      )
                    }
                  />
                ) : (
                  <UpgradeButton />
                )
              }
            </Section>
          )}

          <div
            className={classNames({
              "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center mt-6 sm:mt-5 md:mt-12": true,
            })}
          >
            <div className="col-start-2">
              <Button
                disabled={isLoading || !isValid || !dirty}
                size="small"
                block
                data-testid="company-info-save-button"
              >
                {isLoading ? (
                  <Spinner color="white" />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
