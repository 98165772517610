import React, { FC } from "react";

import { Lighting } from "ovou-ui";

import { Button } from "../Button";
import { Flex } from "../Container";
import { Text } from "../Typography";
import { useUpgradeModal } from "hooks/useUpgradeModal";
import { useTracking } from "hooks";
import { FEATURES } from "ovou-ui";

export const UpgradeButton: FC = () => {
  const track = useTracking();
  const { openModal } = useUpgradeModal();

  const handleClick = () => {
    track?.({ upgrade: true, customTrackingProps: { source: FEATURES.REQUIRE_COMPANY_NAME } });
    openModal?.();
  };

  return (
    <Button
      size="sm"
      padding="0 5px 0 10px"
      height="32px"
      variant="outline"
      _focus={{ outline: "none" }}
      onClick={handleClick}
    >
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Text fontWeight="600" marginRight="5px">
          Upgrade
        </Text>
        <Lighting />
      </Flex>
    </Button>
  );
};
