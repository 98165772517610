import { useQuery } from "react-query";
import api from "./index";

interface IProfile {
  first_name: string;
  last_name: string;
  eff_email: string;
  team_id: string;
}

export const useMyProfile = () => {
  return useQuery<IProfile>(
    ["my-profile", "default"],
    async () => {
      const { data } = await api.get("/profile/my", {
        params: {
          state: "personal",
        },
      });
      return data.profiles[0];
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    },
  );
};
