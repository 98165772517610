import { useEffect, useState } from "react";
import { useBoolean } from "@chakra-ui/react";
import { useCustomerPortal } from "api/customerPortal";
import { CHECKOUT_URL } from "constants/pages";
import { CHECKOUT_TYPE } from "constants/Texts";
import { useQuery } from "react-query";

interface UseManageSubscriptionReturn {
  isManageSubscriptionOpen: boolean;
  closeManageSubscription: () => void;
  customerPortal: string;
  openManageSubscription: () => void;
}

export const useManageSubscription = (): UseManageSubscriptionReturn => {
  const [customerPortal, setCustomerPortal] = useState("");
  const [
    isManageSubscriptionOpen,
    { on: openManageSubscription, off: closeManageSubscription },
  ] = useBoolean();
  const { mutate: fireCustomerPortal } = useCustomerPortal();

  const queryParams = new URLSearchParams(window.location.search);
  const manageSubscription = queryParams.get("manage_subscription");

  useQuery({
    queryKey: ["customerPortal", isManageSubscriptionOpen],
    queryFn: async () => {
      if (isManageSubscriptionOpen || manageSubscription === "true") {
        const data = await fireCustomerPortal({
          data: {
            cancel_url: CHECKOUT_URL.CANCEL,
            success_url: CHECKOUT_URL.SUCCESS,
            type: CHECKOUT_TYPE,
          },
        });
        if (data?.url) {
          setCustomerPortal(data.url);
          openManageSubscription();
        }
        return data;
      }
    },
    enabled: isManageSubscriptionOpen || manageSubscription === "true",
  });

  return {
    isManageSubscriptionOpen,
    closeManageSubscription,
    openManageSubscription,
    customerPortal,
  };
};
