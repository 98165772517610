import {
  FEATURES,
  useGetPermissions,
} from "ovou-ui";

import { usePermissionsState } from "context";

export interface IProfilePermission {
  canViewCustomColor: boolean;
  canEditCustomColor: boolean;
  canViewLogo: boolean;
  canEditLogo: boolean;
  canViewCover: boolean;
  canEditCover: boolean;
  canViewTeamRequireCompanyName: boolean;
  canEditTeamRequireCompanyName: boolean;
}

const useProfilePermission = (): IProfilePermission => {
  const { permission } = usePermissionsState();
  const { default_permission: permissionSet } = permission || {};
  const logo = useGetPermissions(permissionSet || null, FEATURES.SETTING_BRANDING_LOGO);
  const cover = useGetPermissions(
    permissionSet || null,
    FEATURES.SETTING_BRANDING_COVER,
  );

  const customColor = useGetPermissions(
    permissionSet || null,
    FEATURES.SETTING_BRANDING_CUSTOM_COLOR,
  );

  const teamRequireCompanyName = useGetPermissions(
    permissionSet || null,
    FEATURES.TEAM_REQUIRE_COMPANY_NAME,
  );

  return {
    canViewCustomColor: customColor?.view.enabled,
    canEditCustomColor: customColor?.edit.enabled,
    canViewLogo: logo?.view?.enabled,
    canEditLogo: logo?.edit.enabled,
    canViewCover: cover?.view?.enabled,
    canEditCover: cover?.edit?.enabled,
    canViewTeamRequireCompanyName: teamRequireCompanyName?.view?.enabled,
    canEditTeamRequireCompanyName: teamRequireCompanyName?.edit?.enabled,
  };
};
export default useProfilePermission;
