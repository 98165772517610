import { HStack, VStack, Text, Button, Input } from "@chakra-ui/react";
import { TEXTS } from "constants/Texts";
import { theme } from "ovou-ui";
import { FC } from "react";

interface IQuantitySelector {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  step?: number;
  isDisabled?: boolean;
}

export const QuantitySelector: FC<IQuantitySelector> = ({
  value,
  onChange,
  min = 1,
  step = 1,
  isDisabled = false,
}) => {
  const handleDecrement = () => {
    if (value > min) onChange(value - step);
  };

  const handleIncrement = () => {
    onChange(value + step);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow empty input
    if (inputValue === "") {
      onChange(0);
      return;
    }

    const newValue = parseInt(inputValue, 10);
    if (isNaN(newValue)) return;

    onChange(newValue);
  };

  return (
    <VStack align="stretch" spacing={4} mb={6}>
      <HStack justifyContent="space-between" align="center">
        <Text fontWeight="600">{TEXTS.MANAGE_SUBSCRIPTION.numberOfSeats}</Text>
        <HStack spacing={0}>
          <Button
            onClick={handleDecrement}
            size="md"
            borderRightRadius={0}
            borderWidth="1px"
            bg="white"
            color={theme.palette.brand.primary.black}
            _hover={{
              bg: theme.palette.brand.primary.white,
            }}
            _focus={{
              bg: theme.palette.brand.primary.white,
              outline: "none",
            }}
            isDisabled={isDisabled}
            _disabled={{
              bg: theme.palette.brand.primary.white,
              color: theme.palette.ui.greys.grey2,
            }}
            pointerEvents={isDisabled ? "none" : "auto"}
          >
            −
          </Button>
          <Input
            value={value === 0 ? "" : value}
            onChange={handleInputChange}
            textAlign="center"
            w="50px"
            borderRadius={0}
            borderWidth="1px"
            borderX="none"
            color={theme.palette.brand.primary.black}
            isDisabled={isDisabled}
            type="number"
            step={step}
            sx={{
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "&[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
            _focus={{
              borderColor: "inherit",
              boxShadow: "none",
            }}
          />
          <Button
            onClick={handleIncrement}
            size="md"
            borderLeftRadius={0}
            borderWidth="1px"
            bg="white"
            color={theme.palette.brand.primary.black}
            _hover={{
              bg: theme.palette.brand.primary.white,
            }}
            _focus={{
              bg: theme.palette.brand.primary.white,
              outline: "none",
            }}
            _disabled={{
              bg: theme.palette.brand.primary.white,
              color: theme.palette.ui.greys.grey2,
            }}
            pointerEvents={isDisabled ? "none" : "auto"}
            isDisabled={isDisabled}
          >
            +
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};
