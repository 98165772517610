import { Flex, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";
import { FC } from "react";
import { FiInfo } from "react-icons/fi";

interface IInfoBanner {
  description: string;
}

export const InfoBanner: FC<IInfoBanner> = ({ description }) => {
  return (
    <Flex
      bg={theme.palette.ui.greys.grey5}
      p="16px"
      borderRadius="8px"
      alignItems="center"
      gap="16px"
    >
      <FiInfo size={24} color="#155CB7" />
      <Text fontSize="12px" fontWeight="600" lineHeight="150%">
        {description}
      </Text>
    </Flex>
  );
};
