import { FC, useMemo, useState } from "react";
import { Text, Link, Box, Button, Flex, Skeleton } from "@chakra-ui/react";
import { IoCloseCircle } from "react-icons/io5";
import { DrawerComponent } from "../../components/Drawer";
import { theme } from "ovou-ui";
import { TEXTS } from "constants/Texts";
import { InfoBanner } from "../../components/InfoBanner";
import { QuantitySelector } from "../../components/QuantitySelector";
import { useSubscriptionInfo } from "api/useSubscriptionInfo";
import {
  useSeatManagementPortal,
  useUpdateSeatManagementPortal,
} from "api/customerPortal";
import { CHECKOUT_URL } from "constants/pages";
import { useMyProfile } from "api/profile";

interface ISubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  customerPortal: string;
}

const MIN_SEAT_COUNT = 5;
const ManageSubscription: FC<ISubscriptionModalProps> = ({
  isOpen,
  onClose,
  customerPortal,
}) => {
  const [seatCount, setSeatCount] = useState(MIN_SEAT_COUNT);
  const [isLoadingSwitchToYearly, setIsLoadingSwitchToYearly] = useState(false);
  const { data: myProfile } = useMyProfile();
  const talkToSales = useMemo(() => {
    if (!myProfile) return "";

    const params = new URLSearchParams({
      name: `${myProfile.first_name} ${myProfile.last_name}`,
      email: myProfile.eff_email || "",
      a2: myProfile.team_id?.toString() || "",
    });

    return `https://calendly.com/d/cn2f-7f9-fs8/talk-to-sales?${params.toString()}`;
  }, [myProfile]);

  const {
    data: subscriptionInfo,
    isLoading,
    isFetching,
    refetch: refetchSubscriptionInfo,
  } = useSubscriptionInfo({
    enabled: isOpen,
    staleTime: 0,
    retry: 1,
    onSuccess: data => {
      if (data?.current_subscription?.seats?.total) {
        setSeatCount(data.current_subscription.seats.total);
      }
    },
  });
  const {
    mutate: fireSeatManagementPortal,
    isLoading: isLoadingSeatManagementPortal,
  } = useSeatManagementPortal();
  const {
    mutate: fireUpdateSeatManagementPortal,
    isLoading: isLoadingUpdateSeatManagementPortal,
  } = useUpdateSeatManagementPortal();

  const { seats, reduction_schedule } =
    subscriptionInfo?.current_subscription || {};

  const bannerInfo = useMemo(() => {
    const totalSeats = Number(seats?.total);
    const invitedSeats = Number(seats?.invited);
    const minimumSeats = Number(seats?.minimum);
    if (seatCount > totalSeats) {
      return {
        description: TEXTS.MANAGE_SUBSCRIPTION.additionalSeats,
        isDisabled: false,
      };
    }

    if (seatCount < invitedSeats && invitedSeats > minimumSeats) {
      return {
        description:
          TEXTS.MANAGE_SUBSCRIPTION.decreaseSeatForActiveUsers.replace(
            /{count}/g,
            invitedSeats.toString() || "0",
          ),
        isDisabled: true,
      };
    }

    if (seatCount < minimumSeats) {
      return {
        description: TEXTS.MANAGE_SUBSCRIPTION.minimumSeatRequired.replace(
          /{count}/g,
          (minimumSeats || MIN_SEAT_COUNT).toString(),
        ),
        isDisabled: true,
      };
    }

    if (reduction_schedule?.message) {
      return {
        description: reduction_schedule?.message,
      };
    }
    return {
      description: "",
      isDisabled: false,
    };
  }, [seatCount, seats, reduction_schedule]);

  const handleSeatManagementPortal = async (switchToYearly: boolean) => {
    setIsLoadingSwitchToYearly(switchToYearly);
    const data = await fireSeatManagementPortal({
      data: {
        cancel_url: CHECKOUT_URL.CANCEL,
        success_url: CHECKOUT_URL.SUCCESS,
        current_seat_count: seatCount,
        is_decrease: seatCount < Number(seats?.total),
        switch_to_yearly: switchToYearly,
      },
    });
    setIsLoadingSwitchToYearly(false);
    if (data?.url) {
      window.location.href = data?.url;
    }
  };

  const handleClose = () => {
    onClose();

    const url = new URL(window.location.href);
    url.searchParams.delete("manage_subscription");
    window.history.pushState({}, "", url.toString());
  };
  const handleCancelSchedule = async () => {
    await fireUpdateSeatManagementPortal();
    await refetchSubscriptionInfo();
  };

  const { description, isDisabled } = bannerInfo;
  const billingPortalText =
    TEXTS.MANAGE_SUBSCRIPTION.description.split("{Billing Portal}");

  return (
    <DrawerComponent isOpen={isOpen} onClose={onClose}>
      <Box padding="24px">
        <Text
          fontSize="24px"
          fontWeight="800"
          lineHeight="125%"
          margin=" 24px 0 12px"
          color={theme.palette.brand.primary.black}
        >
          {TEXTS.MANAGE_SUBSCRIPTION.title}
        </Text>
        <Box
          onClick={handleClose}
          position="absolute"
          top="24px"
          right="24px"
          cursor="pointer"
        >
          <IoCloseCircle size={32} color="rgba(0, 0, 0, 0.30)" />
        </Box>

        <Text mb="40px">
          {billingPortalText[0]}
          <Text as="span" fontWeight="600">
            <Link
              color={theme.palette.brand.primary.orange}
              href={customerPortal}
              target="_blank"
            >
              {TEXTS.MANAGE_SUBSCRIPTION.billingPortal}
            </Link>
          </Text>
          {billingPortalText[1]}
        </Text>

        <Box
          p="16px"
          borderRadius="10px"
          mb="24px"
          boxShadow="0px 6px 20px 0px rgba(0, 0, 0, 0.12);"
        >
          <Text fontWeight="medium" mb={1}>
            Current Plan
          </Text>
          <Flex>
            <Text
              fontSize="24px"
              fontWeight="800"
              mb={2}
              bgGradient="linear-gradient(94deg, #51025E 1.16%, #BE1DD8 100%)"
              bgClip="text"
            >
              OVOU Teams
            </Text>
          </Flex>
          <Text fontSize="sm" mb="24px">
            {TEXTS.MANAGE_SUBSCRIPTION.planDetailsMessage}
            <Text as="span" fontWeight="600">
              <Link
                color={theme.palette.brand.primary.orange}
                href={talkToSales}
                ml="3px"
                target="_blank"
              >
                {TEXTS.MANAGE_SUBSCRIPTION.talkToSales}
              </Link>
            </Text>
          </Text>

          {isLoading || isFetching ? (
            <Skeleton
              height="40px"
              mb="16px"
              startColor={theme.palette.ui.greys.grey5}
              endColor={theme.palette.ui.greys.grey4}
            />
          ) : (
            <>
              <QuantitySelector
                value={seatCount}
                onChange={setSeatCount}
                isDisabled={!!reduction_schedule?.message}
              />
              {description && <InfoBanner description={description} />}
              {seatCount !== Number(seats?.total) && (
                <Button
                  w="100%"
                  mt="24px"
                  isDisabled={isDisabled}
                  onClick={() => handleSeatManagementPortal(false)}
                  isLoading={
                    isLoadingSeatManagementPortal ||
                    isLoadingUpdateSeatManagementPortal
                  }
                >
                  {seatCount > Number(seats?.total)
                    ? TEXTS.MANAGE_SUBSCRIPTION.continueToIncreaseSeat
                    : TEXTS.MANAGE_SUBSCRIPTION.continueToReduceSeat}
                </Button>
              )}
              {reduction_schedule?.message && (
                <Button
                  w="100%"
                  mt="24px"
                  isDisabled={isDisabled}
                  onClick={handleCancelSchedule}
                  isLoading={isLoadingUpdateSeatManagementPortal}
                >
                  Cancel Schedule
                </Button>
              )}
            </>
          )}
        </Box>

        {isLoading ? (
          <Skeleton
            height="60px"
            borderRadius="10px"
            startColor={theme.palette.ui.greys.grey5}
            endColor={theme.palette.ui.greys.grey4}
          />
        ) : (
          <>
            {subscriptionInfo?.show_yearly_only ? (
              <Flex align="center" justifyContent="center">
                <Text color="#469D50" fontWeight="600">
                  {TEXTS.MANAGE_SUBSCRIPTION.yearlyDiscount}
                </Text>
              </Flex>
            ) : (
              <Box
                bg={theme.palette.ui.greys.grey3}
                p="16px"
                borderRadius="10px"
              >
                <Text>
                  {TEXTS.MANAGE_SUBSCRIPTION.yearlyDiscountDescription}
                </Text>
                <Flex width="100%" justifyContent="flex-end">
                  <Button
                    bg="#469D50"
                    color="white"
                    _hover={{
                      bg: "#3c8544",
                    }}
                    _focus={{
                      bg: "#469D50",
                      boxShadow: "0 0 0 3px rgba(70, 157, 80, 0.3)",
                      outline: "none",
                    }}
                    _active={{
                      bg: "#3c8544",
                    }}
                    transition="all 0.2s ease"
                    width="fit-content"
                    onClick={() => handleSeatManagementPortal(true)}
                    isLoading={isLoadingSwitchToYearly}
                  >
                    {TEXTS.MANAGE_SUBSCRIPTION.switchToYearly}
                  </Button>
                </Flex>
              </Box>
            )}
          </>
        )}
      </Box>
    </DrawerComponent>
  );
};

export default ManageSubscription;
