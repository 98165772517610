import { datadogRum } from "@datadog/browser-rum";
import { ENVIRONMENT, HIGHER_ENVIRONMENT, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SITE } from "constants/index";

function allowTag(path, value) {
    var NOT_ALLOWED_TAGS = [
        "access_token",
        "accessToken",
        "refresh_token",
        "refreshToken",
        "password",
        "api_key",
        "apiKey",
        "Authorization",
        "secret",
        "action_token",
        "actionToken",
        "provider_account_id",
        "providerAccountId",
        "session_key",
        "sessionKey",
    ];

    var REDACTED_TEXT = "REDACTED";

    if (path.length > 0) {
        const key = path[path.length - 1];

        if (NOT_ALLOWED_TAGS.includes(key)) {
            return REDACTED_TEXT;
        }
    }

    if (
        typeof value === "string" &&
        // String starts with Bearer or string is a JWT token
        (value.startsWith("Bearer") || value.includes("eyJ"))
    ) {
        return REDACTED_TEXT;
    }

    return value;
}

function isObject(value) {
    return typeof value === "object" && value !== null;
}

function redactSensitiveTags(tags, path) {
    if (isObject(tags)) {
        for (const key of Object.keys(tags)) {
            tags[key] = redactSensitiveTags(tags[key], [...path, key]);
        }
        return tags;
    } else {
        return allowTag(path, tags);
    }
}

export const initializeDatadogRum = () => {
    if (HIGHER_ENVIRONMENT.includes(ENVIRONMENT)) {
        datadogRum.init({
            applicationId: DATADOG_APPLICATION_ID,
            clientToken: DATADOG_CLIENT_TOKEN,
            site: DATADOG_SITE,
            service: "admin-front",
            env: ENVIRONMENT,
            version: "1.0.0",
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
            allowedTracingUrls: [
                { match: "http://localhost", propagatorTypes: ["tracecontext"] },
                { match: /https:\/\/.*\.ovou\.com/, propagatorTypes: ["tracecontext"] },
                { match: (url) => url.startsWith("https://ovou.com"), propagatorTypes: ["tracecontext"] },
            ],
            beforeSend: function (event, context) {
                redactSensitiveTags(event, []);
                return true;
            },
        });
    }
};
